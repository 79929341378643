import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import { AlertConfirm, Button } from '../../../components';
import 'moment/locale/fr';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import get from 'lodash/get';
import { updateDevisMaterials } from '../../../helpers/actions/projects';
import MaterialCard from './MaterialCard';
import MaterialOrderModal from './MaterialOrderModal';
import Invoices from './Invoices';
import cloneDeep from 'lodash/cloneDeep';

class OrderCard extends Component {
  state = {
    loading: false,
    devis: this.props.devis,
    selected: false,
    grouped: [],
    newMaterial: false,
    indexGroupDelete: null,
    indexMatDelete: null,
    saveAnimation: false
  };

  componentWillMount() {
    let { devis } = this.props;
    let grouped = [];

    if (devis && devis.materials) {
      devis.materials.forEach(element => {
        let index = grouped.findIndex(t => t.name === element.piece);
        if (index !== -1) {
          grouped[index].materials.push(element);
        } else {
          grouped.push({
            name: element.piece,
            materials: [element]
          });
        }
      });

      this.setState({ grouped, saveAnimation: false });
    }
  }

  editMaterial(material, indexG, indexM) {
    let { grouped } = this.state;

    if (
      grouped &&
      grouped[indexG] &&
      grouped[indexG].materials &&
      grouped[indexG].materials[indexM]
    ) {
      grouped[indexG].materials[indexM] = material;

      this.setState({ grouped, saveAnimation: true });
    }
  }

  addMaterial(material) {
    let { grouped } = this.state;

    if (material && material.piece && material.group) {
      let index = grouped.findIndex(t => t.name === material.piece);

      if (index !== -1) {
        grouped[index].materials.push(material);
      } else {
        grouped.push({
          name: material.piece,
          materials: [material]
        });
      }

      this.setState({ grouped, saveAnimation: true });
    }
  }

  deleteMaterial(indexG, indexM) {
    let { grouped } = this.state;

    if (
      grouped &&
      grouped[indexG] &&
      grouped[indexG].materials &&
      grouped[indexG].materials[indexM]
    ) {
      grouped[indexG].materials.splice(indexM, 1);

      if (grouped[indexG].materials.length === 0) grouped.splice(indexG, 1);

      this.setState({ grouped, saveAnimation: true });
    }
  }

  saveOrder() {
    this.setState({ loading: true });

    let { devis, grouped } = this.state,
      { dispatch } = this.props;

    let materials = [];
    grouped.forEach(group => {
      group.materials.forEach(material => {
        materials.push(material);
      });
    });

    if (devis && devis.project) {
      dispatch(updateDevisMaterials(devis.project, devis._id, materials))
        .then(() =>
          this.setState(ps => ({ ...ps, loading: false, saveAnimation: false }))
        )
        .catch(() => this.setState(ps => ({ ...ps, loading: false })));
    }
  }

  render() {
    let {
        devis,
        selected,
        grouped,
        newMaterial,
        indexGroupDelete,
        indexMatDelete,
        saveAnimation
      } = this.state,
      { filters } = this.props;

    return (
      <div>
        <Card>
          <CardHeader className={'pt-2'}>
            <div className="table-full-width table-responsive">
              <table className="table table-no-border m-0">
                <tbody>
                  <tr>
                    <td className="text-left">
                      <h6>{get(devis, 'title', '')}</h6>
                    </td>
                    <td className="td-actions text-right">
                      <Button
                        color={'info'}
                        onClick={() =>
                          this.setState(ps => ({
                            ...ps,
                            newMaterial: true
                          }))
                        }
                      >
                        <i className="now-ui-icons ui-1_simple-add" />{' '}
                        <Trans>Material</Trans>
                      </Button>
                      <Button
                        color={'info'}
                        icon={true}
                        className={`ml-1 ${
                          saveAnimation ? 'scale-up-center' : ''
                        }`}
                        onClick={() => this.saveOrder()}
                      >
                        <i className="fas fa-save" />{' '}
                      </Button>
                      <Button
                        color="default"
                        neutral
                        round
                        icon
                        onClick={() => this.setState({ selected: !selected })}
                      >
                        <i
                          className={`fa ${selected ? 'fa-minus' : 'fa-plus'}`}
                        />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardHeader>
          <CardBody
            className={'pt-0 pb-0'}
            style={{ display: selected ? 'block' : 'none' }}
          >
            <hr className={'mt-0'} />

            <div className="my-2 p-2 bg-default text-white row">
              <Col className="text-center">
                <h6>
                  <Trans>Orders</Trans>
                </h6>
              </Col>
            </div>
            {grouped && grouped.length ? (
              grouped.map((group, keyG) => {
                return (
                  <Row key={keyG}>
                    <Col xs={12}>
                      <h5>
                        <div className={'badge badge-info'}>
                          {get(group, 'name', ' ')}
                        </div>
                      </h5>
                    </Col>
                    <Col xs={12}>
                      {group.materials.map((mat, keyM) => {
                        return (!filters.material ||
                          (filters.material &&
                            mat.name
                              .toUpperCase()
                              .includes(filters.material.toUpperCase()))) &&
                          (!filters.responsible ||
                            (filters.responsible &&
                              filters.responsible === mat.responsible)) &&
                          (!filters.status ||
                            (filters.status &&
                              filters.status === mat.status)) ? (
                          <MaterialCard
                            key={keyM}
                            material={mat}
                            editMaterial={material =>
                              this.editMaterial(material, keyG, keyM)
                            }
                            deleteMaterial={() =>
                              this.setState(ps => ({
                                ...ps,
                                indexGroupDelete: keyG,
                                indexMatDelete: keyM
                              }))
                            }
                          />
                        ) : null;
                      })}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <p className={'text-not-found text-center'}>
                <Trans>No materials found</Trans>
              </p>
            )}

            <div className="my-2 p-2 bg-default text-white row">
              <Col className="text-center">
                <h6>
                  <Trans>Invoices</Trans>
                </h6>
              </Col>
            </div>
            <Row>
              <Col xs={12}>
                <Invoices projectId={devis.project} devisId={devis._id} />
              </Col>
            </Row>
          </CardBody>
        </Card>

        {newMaterial ? (
          <MaterialOrderModal
            projectType={this.props.projectType}
            onCancel={() => this.setState({ newMaterial: false })}
            onConfirm={material =>
              this.setState(
                prevState => {
                  return { ...prevState, newMaterial: false };
                },
                () => this.addMaterial(material)
              )
            }
          />
        ) : null}

        {indexGroupDelete !== null &&
        indexGroupDelete >= 0 &&
        indexMatDelete !== null &&
        indexMatDelete >= 0 ? (
          <AlertConfirm
            message={'The material cannot be recovered'}
            onCancel={() =>
              this.setState({ indexGroupDelete: null, indexMatDelete: null })
            }
            onConfirm={() => {
              let materialIndex = cloneDeep(indexMatDelete);
              let groupIndex = cloneDeep(indexGroupDelete);
              this.setState(
                { indexGroupDelete: null, indexMatDelete: null },
                () => this.deleteMaterial(groupIndex, materialIndex)
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(OrderCard));
