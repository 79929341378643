import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import ReactQuill from 'react-quill';
import { Spinner } from '../../components';
import get from 'lodash/get';
import { photo, save } from '../../helpers/actions/partners';
import defaultAvatar from '../../assets/img/default-avatar.png';
import { quillConfig } from '../../helpers/formatters';

class PartnerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      partner: {
        name: '',
        email: '',
        description: '',
        phone: {}
      },
      file: null
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    let { partner } = this.props;
    if (partner && !partner.phone) partner.phone = {};

    this.setState({
      partner: partner
        ? partner
        : {
            name: '',
            description: '',
            email: '',
            website: '',
            phone: {}
          },
      file: null,
      nameValid: '',
      emailValid: '',
      mobileValid: '',
      workValid: '',
      landlineValid: '',
      websiteValid: '',
      loading: false
    });
  }

  handleImageChange(file) {
    let { partner } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      partner.photo = reader.result;
      this.setState({ partner, file: file });
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  onChangePartner(name, nameValid, value, valid) {
    let { partner } = this.state;

    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState({
        partner: { ...partner, phone: { ...partner.phone, [name]: value } },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      });
    } else {
      this.setState({
        partner: { ...partner, [name]: value },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      });
    }
  }

  validatePartner() {
    let name = this.name;
    let email = this.email;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;
    let website = this.website;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [website.attributes.getNamedItem('namevalid').value]: website.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      email.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid &&
      website.validity.valid
    );
  }

  async onSavePartner() {
    if (this.validatePartner()) {
      this.setState({ loading: true });

      let { partner, file } = this.state,
        { dispatch } = this.props;

      delete partner.photo;
      dispatch(save(partner))
        .then(partnerRes => {
          if (partnerRes._id && file) {
            dispatch(photo(partnerRes._id, file))
              .then(() => this.props.onConfirm(partnerRes))
              .catch(() => this.props.onConfirm(partnerRes));
          } else this.props.onConfirm(partnerRes);
        })
        .catch(() => this.props.onCancel());
    }
  }

  render() {
    let { loading, partner } = this.state,
      { t } = this.props;

    return (
      <Modal
        size="lg"
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          {partner._id ? t('Edit Partner') : t('New Partner')}
        </ModalHeader>
        <ModalBody>
          {partner ? (
            <Form className="form-horizontal">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  className={'display-flex justify-content-center'}
                >
                  <div
                    className="avatar-image"
                    style={{
                      backgroundImage: `url('${get(
                        partner,
                        'photo',
                        defaultAvatar
                      )}')`
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  md={12}
                  className={'justify-content-center text-center'}
                >
                  <Button
                    color={'info'}
                    round="true"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Change</Trans>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.nameValid
                    }
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={partner.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChangePartner(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.emailValid
                    }
                  >
                    <Label>
                      <Trans>Email</Trans>
                    </Label>
                    <Input
                      type="email"
                      innerRef={node => (this.email = node)}
                      namevalid="emailValid"
                      value={partner.email || ''}
                      required="required"
                      onChange={event =>
                        this.onChangePartner(
                          'email',
                          'emailValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.websiteValid
                    }
                  >
                    <Label>
                      <Trans>Website</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.website = node)}
                      namevalid="websiteValid"
                      value={partner.website || ''}
                      onChange={event =>
                        this.onChangePartner(
                          'website',
                          'websiteValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <ReactQuill
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={partner.description || ''}
                      modules={quillConfig}
                      className={'quill-text-editor'}
                      onChange={value =>
                        this.onChangePartner(
                          'description',
                          'descriptionValid',
                          value,
                          true
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.mobileValid
                    }
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={partner.phone.mobile || ''}
                      onChange={event =>
                        this.onChangePartner(
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.workValid
                    }
                  >
                    <Label>
                      <Trans>Work</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={partner.phone.work || ''}
                      onChange={event =>
                        this.onChangePartner(
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.landlineValid
                    }
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={partner.phone.landline || ''}
                      onChange={event =>
                        this.onChangePartner(
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.onCancel()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSavePartner()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(PartnerModal));
