import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import { Slider } from '../../../components';
import get from 'lodash/get';
import { updateSellers } from '../../../helpers/actions/projects';
import { sellerName } from '../../../helpers/formatters';
import SellersListModal from './SellersListModal';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { isMobileOnly } from 'react-device-detect';

class AssignSeller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      assignSeller: false
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({ project: nextProps.project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  updateSellers(newSeller, assign = true) {
    let { dispatch } = this.props,
      { project } = this.state;

    if (!newSeller._id || !project._id) {
      this.setState({ assignSeller: false });
    } else
      dispatch(
        updateSellers(project._id, {
          seller: newSeller._id,
          assign
        })
      )
        .then(() =>
          this.setState({ assignSeller: false }, () => this.props.reload())
        )
        .catch(() => this.setState({ assignSeller: false }));
  }

  createSlider(project) {
    return project.sellers.map(s => (
      <div key={s._id}>
        <Button
          color="danger"
          size="sm"
          className={`ml-3 pl-2 pr-2 ${
            isMobileOnly ? 'float-right' : 'delete-user-slider'
          }`}
          onClick={() => this.updateSellers(s, false)}
        >
          <i className="fa fa-trash icon-action" />
        </Button>
        <div
          className="project-detail-avatar"
          style={{
            backgroundImage: `url('${s.photoURL ? s.photoURL : defaultAvatar}')`
          }}
        />
        <CardTitle tag="h5" className={'text-center'}>
          {sellerName(s)}
        </CardTitle>
        {s ? (
          <div>
            <ul>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Email</Trans>:
                </strong>
                {get(s, 'email', '') ? (
                  <a
                    href={`mailto:${get(s, 'email', '')}?Subject=Placeshaker`}
                    target="_top"
                  >
                    {get(s, 'email', '')}
                  </a>
                ) : null}
              </li>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Address</Trans>:
                </strong>
                {s.lat && s.lng ? (
                  isMobileOnly ? (
                    <a
                      href={`https://www.waze.com/ul?ll=${s.lat}%2C${
                        s.lng
                      }&zoom=17`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(s, 'address', '')}
                    </a>
                  ) : (
                    <a
                      href={`https://www.google.com/maps/place/${get(
                        s,
                        'address',
                        ''
                      )}/@=${s.lat},${s.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(s, 'address', '')}
                    </a>
                  )
                ) : (
                  get(s, 'address', '')
                )}
              </li>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Mobile</Trans>:
                </strong>
                <a href={`tel:${get(s, 'phone.mobile', '')}`}>
                  {get(s, 'phone.mobile', '')}
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    ));
  }

  render() {
    let { project, assignSeller } = this.state;

    return [
      isMobileOnly ? (
        <Row key={'assignSeller'} className="user-data">
          <Col xs={12}>
            {project.sellers && project.sellers.length ? (
              <Slider slides={this.createSlider(project)} id={'sellers'} />
            ) : (
              <p className="text-center">
                <Trans>Assign this project to a seller</Trans>
              </p>
            )}
          </Col>
          <Col xs={12} className={'text-center pt-0'}>
            <Button
              color="info"
              size="sm"
              onClick={() => this.setState({ assignSeller: true })}
            >
              <Trans>Assign</Trans>
            </Button>
          </Col>
        </Row>
      ) : (
        <Card key={'assignSeller'} className="card-flex user-data">
          <CardHeader className={'text-center'}>
            <h6>
              <Trans>Sellers</Trans> (
              {project.sellers ? project.sellers.length : 0})
            </h6>
          </CardHeader>
          <CardBody>
            {project.sellers && project.sellers.length ? (
              <Slider slides={this.createSlider(project)} id={'sellers'} />
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p className="text-center">
                    <Trans>Assign this project to a seller</Trans>
                  </p>
                </Col>
              </Row>
            )}
          </CardBody>
          <CardFooter className={'text-center pt-0'}>
            <Button
              color="info"
              size="sm"
              onClick={() => this.setState({ assignSeller: true })}
            >
              <Trans>Assign</Trans>
            </Button>
          </CardFooter>
        </Card>
      ),
      assignSeller ? (
        <SellersListModal
          key={'sellersListModal'}
          show={assignSeller}
          sellers={project.sellers || []}
          dispatch={this.props.dispatch}
          onCancel={() => this.setState({ assignSeller: false })}
          assignSeller={newSeller =>
            this.setState({ assignSeller: false }, () =>
              this.updateSellers(newSeller)
            )
          }
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(AssignSeller));
