import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { all, photo, remove, save } from '../../helpers/actions/providers';
import ProviderCard from './ProviderCard';
import ProviderModal from './ProviderModal';

class Providers extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    provider: null,
    providerDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(search) {
    this.setState({ search, page: 1 }, () => this.load());
  }

  async onSaveProvider(providerEdit, avatar) {
    this.setState({ loading: true });

    let { dispatch } = this.props;

    if (providerEdit.address && providerEdit.address !== '') {
      await geocodeByAddress(providerEdit.address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          providerEdit.lat = lat;
          providerEdit.lng = lng;
        })
        .catch(() => {
          providerEdit.lat = null;
          providerEdit.lng = null;
        });
    } else {
      providerEdit.lat = null;
      providerEdit.lng = null;
    }

    delete providerEdit.logo;
    dispatch(save(providerEdit))
      .then(providerRes => {
        if (providerRes._id && avatar) {
          dispatch(photo(providerRes._id, avatar))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deleteProvider(id) {
    this.setState({ loading: true, providerDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      provider,
      providerDelete
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event => this.filter(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ provider: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((contact, key) => (
                  <Col key={key} xs={12} sm={12} md={6} lg={4}>
                    <ProviderCard
                      provider={contact}
                      onEdit={providerEdit =>
                        this.setState({ provider: providerEdit })
                      }
                      onDelete={deleteProvider =>
                        this.setState({ providerDelete: deleteProvider })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No providers found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {!!provider ? (
            <ProviderModal
              show={!!provider}
              provider={provider}
              onCancel={() => this.setState({ provider: null })}
              onConfirm={(providerEdit, avatar) =>
                this.setState({ provider: null }, () =>
                  this.onSaveProvider(providerEdit, avatar)
                )
              }
            />
          ) : null}

          {!providerDelete ? null : (
            <AlertConfirm
              message={'The provider cannot be recovered'}
              onCancel={() => this.setState({ providerDelete: null })}
              onConfirm={() => this.deleteProvider(providerDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Providers));
