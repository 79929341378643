import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Documents.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const byUser = () => (dispatch, getState, { api }) =>
  api.Documents.byUser().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Documents.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = doc => (dispatch, getState, { api }) =>
  api.Documents.save(doc)
    .then(response => {
      dispatch(notify('info', 'Document saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const update = (id, doc) => (dispatch, getState, { api }) =>
  api.Documents.update(id, doc)
    .then(response => {
      dispatch(notify('info', 'Document saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Documents.del(id)
    .then(response => {
      dispatch(notify('info', 'Documents removed'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
