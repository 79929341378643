import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import get from 'lodash/get';
import { documentKind, documentStatus } from '../../../helpers/nomenclators';

class DocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: {
        name: '',
        description: '',
        expire: '',
        kind: '',
        status: '',
        file: null,
        user: null
      },
      users: []
    };
  }

  componentWillMount() {
    let { document, users } = this.props;
    this.setState(ps => ({
      ...ps,
      document: document
        ? document
        : {
            name: '',
            description: '',
            expire: '',
            kind: '',
            status: '',
            user: null
          },
      nameValid: null,
      descriptionValid: null,
      expireValid: null,
      userValid: null,
      kindValid: null,
      statusValid: null,
      users
    }));
  }

  close() {
    this.props.closeModal();
  }

  onChange(name, nameValid, value, valid) {
    let { document } = this.state;
    this.setState(ps => ({
      ...ps,
      document: { ...document, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    }));
  }

  handleClick() {
    let input = document.getElementById('uploadDocument');
    input.click();
  }

  handleDocumentChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.onChange('file', 'fileValid', file, !!file);
  }

  validateDocument() {
    let { document, users } = this.state;
    let name = this.name;
    let description = this.description;
    let expire = this.expire;

    let found = users.find(function(element) {
      return element._id.toString() === document.user.toString();
    });

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [expire.attributes.getNamedItem('namevalid').value]: expire.validity.valid
        ? 'has-success'
        : 'has-danger',
      userValid: document.user && found ? 'has-success' : 'has-danger',
      kindValid: document.kind ? 'has-success' : 'has-danger',
      statusValid: document.status ? 'has-success' : 'has-danger'
    });

    return (
      name.validity.valid &&
      description.validity.valid &&
      expire.validity.valid &&
      document.user &&
      document.kind &&
      document.status &&
      found
    );
  }

  render() {
    let { document, nameValid, descriptionValid, expireValid } = this.state;
    let { t, users } = this.props;

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {document._id ? t('Edit Document') : t('New Document')}
        </ModalHeader>
        <ModalBody>
          {document ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + nameValid}
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={document.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.fileValid
                    }
                  >
                    <Label>
                      <Trans>Document</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.file = node)}
                      namevalid="fileValid"
                      value={get(document.file, 'name', '')}
                      required="required"
                      enable={'false'}
                      onClick={() => this.handleClick()}
                    />
                    <Button
                      className="float-right"
                      size="sm"
                      color={'info'}
                      onClick={() => this.handleClick()}
                    >
                      <Trans>Upload</Trans>
                    </Button>
                    <input
                      type="file"
                      id="uploadDocument"
                      name="uploadDocument"
                      hidden={true}
                      multiple={false}
                      onChange={event => this.handleDocumentChange(event)}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  <FormGroup className={'has-label ' + this.state.userValid}>
                    <Label>
                      <Trans>Pro</Trans>
                    </Label>
                    <Select
                      clearable={false}
                      className="primary"
                      innerRef={node => (this.user = node)}
                      namevalid="userValid"
                      value={document.user}
                      options={users.map(u => ({
                        label: u.displayName,
                        value: u._id
                      }))}
                      onChange={event =>
                        this.onChange(
                          'user',
                          'userValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + expireValid}
                  >
                    <Label>
                      <Trans>Expire</Trans>
                    </Label>
                    <Datetime
                      locale={'fr'}
                      value={moment(document.expire)}
                      inputProps={{
                        ref: node => (this.expire = node),
                        namevalid: 'expireValid',
                        className: 'form-control datetime-read-only',
                        required: 'required',
                        readOnly: true
                      }}
                      onChange={moment =>
                        this.onChange('expire', 'expireValid', moment, !!moment)
                      }
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  <FormGroup className={'has-label ' + this.state.kindValid}>
                    <Label>
                      <Trans>Type</Trans>
                    </Label>
                    <Select
                      clearable={false}
                      className="primary"
                      innerRef={node => (this.kind = node)}
                      namevalid="kindValid"
                      value={document.kind}
                      options={documentKind.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      onChange={event =>
                        this.onChange(
                          'kind',
                          'kindValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  <FormGroup className={'has-label ' + this.state.statusValid}>
                    <Label>
                      <Trans>Status</Trans>
                    </Label>
                    <Select
                      clearable={false}
                      className="primary"
                      innerRef={node => (this.status = node)}
                      namevalid="statusValid"
                      value={document.status}
                      options={documentStatus.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      onChange={event =>
                        this.onChange(
                          'status',
                          'statusValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={document.description || ''}
                      onChange={event =>
                        this.onChange(
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validateDocument()) {
                this.props.saveDocument(document);
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(DocumentModal)
);
