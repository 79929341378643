import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import get from 'lodash/get';
import { save } from '../../../helpers/actions/projects';
import { providerAvatar, providerName } from '../../../helpers/formatters';
import ProvidersListModal from './ProvidersListModal';

class AssignProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      assignProvider: false
    };
  }

  componentWillReceiveProps({ project }) {
    this.setState({ project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  assignProvider(newProvider) {
    this.setState({ loading: true });
    let { dispatch } = this.props,
      { project } = this.state;

    dispatch(save({ _id: project._id, provider: newProvider }))
      .then(() =>
        this.setState({
          provider: newProvider,
          assignProvider: false,
          newProvider: null,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { project, assignProvider } = this.state,
      { t } = this.props;

    return (
      <Card key={'assignClient'} className="card-flex user-data">
        <CardHeader className={'text-center'}>
          <h6>
            <Trans>Provider</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          <div
            className="project-detail-avatar"
            style={{
              backgroundImage: `url('${providerAvatar(project.provider)}')`
            }}
          />
          <CardTitle tag="h5" className={'text-center'}>
            {providerName(project.provider)}
          </CardTitle>
          {project.provider ? (
            <div>
              <ul>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Email</Trans>:
                  </strong>
                  {get(project, 'client.email', '') ? (
                    <a
                      href={`mailto:${get(
                        project,
                        'provider.contact.email',
                        ''
                      )}?Subject=Placeshaker`}
                      target="_top"
                    >
                      {get(project, 'provider.contact.emai', '')}
                    </a>
                  ) : null}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Address</Trans>:
                  </strong>
                  {get(project, 'provider.address', '') ? (
                    <a
                      href={`https://www.waze.com/ul?ll=${project.provider.lat}%2C${project.provider.lng}&zoom=17`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(project, 'provider.address', '')}
                    </a>
                  ) : null}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Website</Trans>:
                  </strong>
                  {get(project, 'provider.website', '')}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Mobile</Trans>:
                  </strong>
                  <a href={`tel:${get(project, 'provider.phone.mobile', '')}`}>
                    {get(project, 'provider.phone.mobile', '')}
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p className="text-center">
                  {project.type && project.type === 'compound-son'
                    ? t('Assign the parent project to a provider')
                    : t('Assign this project to a provider')}
                </p>
              </Col>
            </Row>
          )}
        </CardBody>
        <CardFooter className={'text-center pt-0'}>
          {!!project.provider ||
          (project.type && project.type === 'compound-son') ? null : (
            <Button
              size="sm"
              color={'info'}
              onClick={() => this.setState({ assignProvider: true })}
            >
              <Trans>Assign</Trans>
            </Button>
          )}
        </CardFooter>

        {assignProvider ? (
          <ProvidersListModal
            show={assignProvider}
            provider={project.provider}
            dispatch={this.props.dispatch}
            onCancel={() => this.setState({ assignProvider: false })}
            assignProvider={newProvider =>
              this.setState({ assignProvider: false }, () =>
                this.assignProvider(newProvider)
              )
            }
          />
        ) : null}
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(AssignProvider));
