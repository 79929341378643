import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import { Spinner } from '../../../components';
import get from 'lodash/get';
import { save } from '../../../helpers/actions/projects';
import { providerAvatar, providerName } from '../../../helpers/formatters';
import ProvidersListModal from './ProvidersListModal';

class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project: {},
      provider: {},
      assignProvider: false
    };
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({
      project,
      provider: project && project.provider ? project.provider : null,
      loading: false
    });
  }

  assignProvider(newProvider) {
    this.setState({ loading: true });
    let { dispatch } = this.props,
      { project } = this.state;

    dispatch(save({ _id: project._id, provider: newProvider }))
      .then(() =>
        this.setState({
          provider: newProvider,
          assignProvider: false,
          newProvider: null,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { loading, provider, project, assignProvider } = this.state,
      { t } = this.props;

    return (
      <div>
        <Card className={'card-plain user-data'}>
          {loading ? <Spinner /> : null}
          <CardHeader className={'pt-0 text-right'}>
            {!!provider ||
            (project.type && project.type === 'compound-son') ? null : (
              <Button
                size="sm"
                color={'info'}
                onClick={() => this.setState({ assignProvider: true })}
              >
                <Trans>Assign</Trans>
              </Button>
            )}
          </CardHeader>
          <CardBody>
            <Row>
              {provider ? (
                [
                  <Col key={'avatar'} xs={12} sm={12} md={2}>
                    <div
                      className="project-detail-avatar"
                      style={{
                        backgroundImage: `url('${providerAvatar(provider)}')`
                      }}
                    />
                    <CardTitle tag="h5" className={'text-center'}>
                      {providerName(provider)}
                    </CardTitle>
                  </Col>,
                  <Col key={'primary'} xs={12} sm={12} md={5}>
                    <ul>
                      <li>
                        <strong className={'mr-1'}>
                          <Trans>Email</Trans>:
                        </strong>
                        {get(provider, 'contact.email', '')}
                      </li>
                      <li>
                        <strong className={'mr-1'}>
                          <Trans>Address</Trans>:
                        </strong>
                        {get(provider, 'address', '')}
                      </li>
                      <li>
                        <strong className={'mr-1'}>
                          <Trans>Website</Trans>:
                        </strong>
                        {get(provider, 'website', '')}
                      </li>
                    </ul>
                  </Col>,
                  <Col key={'secondary'} xs={12} sm={12} md={5}>
                    <ul>
                      <li>
                        <strong className={'mr-1'}>
                          <Trans>Mobile</Trans>:
                        </strong>
                        <a href={`tel:${get(provider, 'phone.mobile', '')}`}>
                          {get(provider, 'phone.mobile', '')}
                        </a>
                      </li>
                      <li>
                        <strong className={'mr-1'}>
                          <Trans>Landline</Trans>:
                        </strong>
                        <a href={`tel:${get(provider, 'phone.landline', '')}`}>
                          {get(provider, 'phone.landline', '')}
                        </a>
                      </li>
                      <li>
                        <strong className={'mr-1'}>
                          <Trans>Work</Trans>:
                        </strong>
                        <a href={`tel:${get(provider, 'phone.work', '')}`}>
                          {get(provider, 'phone.work', '')}
                        </a>
                      </li>
                    </ul>
                  </Col>
                ]
              ) : (
                <Col xs={12}>
                  <p className="text-center">
                    {project.type && project.type === 'compound-son'
                      ? t('Assign the parent project to a provider')
                      : t('Assign this project to a provider')}
                  </p>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        {assignProvider ? (
          <ProvidersListModal
            show={assignProvider}
            provider={provider}
            dispatch={this.props.dispatch}
            onCancel={() => this.setState({ assignProvider: false })}
            assignProvider={newProvider =>
              this.setState({ assignProvider: false }, () =>
                this.assignProvider(newProvider)
              )
            }
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Provider));
