import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  Input
} from 'reactstrap';
import { Button } from '../../../components/index';
import Select from 'react-select';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import get from 'lodash/get';
import { orderResponsible, orderStatus } from '../../../helpers/nomenclators';
import defaultImage from '../../../assets/img/image_placeholder.jpg';

class MaterialCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: {},
      selected: false
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState(ps => ({
      ...ps,
      material: nextProps.material
    }));
  }

  componentWillMount() {
    let { material } = this.props;
    this.setState({ material });
  }

  onChange(name, value) {
    let { material } = this.state;

    material[name] = value;
    this.props.editMaterial(material);
  }

  render() {
    let { material, selected } = this.state,
      { t } = this.props;

    return (
      <div>
        <Card className={'card-plain m-0'}>
          <CardHeader className={'p-0'}>
            <div className="table-full-width table-responsive">
              <table className="table table-no-border m-0">
                <tbody>
                  <tr>
                    <td width={'5%'}>
                      <div
                        className="piece-surface-image"
                        style={{
                          backgroundImage: `url('${
                            material.image ? material.image : defaultImage
                          }')`
                        }}
                      />
                    </td>
                    <td className="text-left py-0">
                      <h6>{get(material, 'name', '')}</h6>
                    </td>
                    <td className="td-actions text-right py-0">
                      <Button
                        color="default"
                        neutral
                        round
                        icon
                        onClick={() => this.setState({ selected: !selected })}
                      >
                        <i
                          className={`fa ${selected ? 'fa-minus' : 'fa-plus'}`}
                        />
                      </Button>
                      <Button
                        color={'danger'}
                        size="sm"
                        onClick={() => this.props.deleteMaterial()}
                      >
                        <i className="fa fa-trash icon-action" />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className={'m-0'} />
          </CardHeader>
          <CardBody className={`pb-0 ${selected ? 'd-block' : 'd-none'}`}>
            <Row>
              <Col xs={12} md={2}>
                <FormGroup className={'has-label text-center'}>
                  <Label>
                    <Trans>Responsible</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={orderResponsible.map(res => ({
                      label: t(res),
                      value: res
                    }))}
                    value={material.responsible}
                    onChange={event =>
                      this.onChange('responsible', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup className={'has-label text-center'}>
                  <Label>
                    <Trans>Availability Date</Trans>
                  </Label>
                  <Datetime
                    locale={'fr'}
                    value={moment(material.availability_date)}
                    inputProps={{
                      readOnly: true
                    }}
                    onChange={moment =>
                      this.onChange('availability_date', moment)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup className={'has-label text-center'}>
                  <Label>
                    <Trans>Unit Price</Trans>
                  </Label>
                  <Input
                    type="number"
                    disabled
                    value={material.unit_price || ''}
                    step={0.01}
                    min={0.01}
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup className={'has-label text-center'}>
                  <Label>
                    <Trans>Received Amount</Trans>
                  </Label>
                  <Input
                    type="number"
                    value={material.cant_received || ''}
                    step={0.01}
                    min={0.01}
                    onChange={event =>
                      this.onChange('cant_received', event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup className={'has-label text-center'}>
                  <Label>
                    <Trans>Status</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={orderStatus.map(res => ({
                      label: t(res),
                      value: res
                    }))}
                    value={material.status}
                    onChange={event =>
                      this.onChange('status', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2}>
                <FormGroup className={'has-label text-center'}>
                  <Label>
                    <Trans>Code</Trans>
                  </Label>
                  <Input
                    type="number"
                    value={material.code || ''}
                    step={1}
                    min={1}
                    onChange={event =>
                      this.onChange('code', event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MaterialCard));
