import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import { Slider } from '../../../components';
import get from 'lodash/get';
import { updateSupervisors } from '../../../helpers/actions/projects';
import { supervisorName } from '../../../helpers/formatters';
import SupervisorsListModal from './SupervisorsListModal';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { isMobileOnly } from 'react-device-detect';

class AssignSupervisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      assignSupervisor: false
    };
  }

  componentWillReceiveProps({ project }) {
    this.setState({ project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  updateSupervisors(newSupervisor, assign = true) {
    let { dispatch } = this.props,
      { project } = this.state;

    if (!newSupervisor._id || !project._id) {
      this.setState({ assignSupervisor: false });
    } else
      dispatch(
        updateSupervisors(project._id, {
          supervisor: newSupervisor._id,
          assign
        })
      )
        .then(() =>
          this.setState({ assignSupervisor: false }, () => this.props.reload())
        )
        .catch(() => this.setState({ assignSupervisor: false }));
  }

  createSlider(project) {
    return project.supervisors.map(s => (
      <div key={s._id}>
        <Button
          color="danger"
          size="sm"
          className={`ml-3 pl-2 pr-2 ${
            isMobileOnly ? 'float-right' : 'delete-user-slider'
          }`}
          onClick={() => this.updateSupervisors(s, false)}
        >
          <i className="fa fa-trash icon-action" />
        </Button>
        <div
          className="project-detail-avatar"
          style={{
            backgroundImage: `url('${s.photoURL ? s.photoURL : defaultAvatar}')`
          }}
        />
        <CardTitle tag="h5" className={'text-center'}>
          {supervisorName(s)}
        </CardTitle>
        {s ? (
          <div>
            <ul>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Email</Trans>:
                </strong>
                {get(s, 'email', '') ? (
                  <a
                    href={`mailto:${get(s, 'email', '')}?Subject=Placeshaker`}
                    target="_top"
                  >
                    {get(s, 'email', '')}
                  </a>
                ) : null}
              </li>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Address</Trans>:
                </strong>
                {s.lat && s.lng ? (
                  isMobileOnly ? (
                    <a
                      href={`https://www.waze.com/ul?ll=${s.lat}%2C${
                        s.lng
                      }&zoom=17`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(s, 'address', '')}
                    </a>
                  ) : (
                    <a
                      href={`https://www.google.com/maps/place/${get(
                        s,
                        'address',
                        ''
                      )}/@=${s.lat},${s.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(s, 'address', '')}
                    </a>
                  )
                ) : (
                  get(s, 'address', '')
                )}
              </li>
              <li>
                <strong className={'mr-1'}>
                  <Trans>Mobile</Trans>:
                </strong>
                <a href={`tel:${get(s, 'phone.mobile', '')}`}>
                  {get(s, 'phone.mobile', '')}
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    ));
  }

  render() {
    let { project, assignSupervisor } = this.state;

    return [
      isMobileOnly ? (
        <Row key={'assignSupervisor'} className="user-data">
          <Col xs={12}>
            {project.supervisors && project.supervisors.length ? (
              <Slider slides={this.createSlider(project)} id={'supervisors'} />
            ) : (
              <p className="text-center">
                <Trans>Assign this project to a supervisor</Trans>
              </p>
            )}
          </Col>
          <Col xs={12} className={'text-center pt-0'}>
            <Button
              color="info"
              size="sm"
              onClick={() => this.setState({ assignSupervisor: true })}
            >
              <Trans>Assign</Trans>
            </Button>
          </Col>
        </Row>
      ) : (
        <Card key={'assignSupervisor'} className="card-flex user-data">
          <CardHeader className={'text-center'}>
            <h6>
              <Trans>Supervisors</Trans> (
              {project.supervisors ? project.supervisors.length : 0})
            </h6>
          </CardHeader>

          <CardBody>
            {project.supervisors && project.supervisors.length ? (
              <Slider slides={this.createSlider(project)} id={'supervisors'} />
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p className="text-center">
                    <Trans>Assign this project to a supervisor</Trans>
                  </p>
                </Col>
              </Row>
            )}
          </CardBody>
          <CardFooter className={'text-center pt-0'}>
            <Button
              color="info"
              size="sm"
              onClick={() => this.setState({ assignSupervisor: true })}
            >
              <Trans>Assign</Trans>
            </Button>
          </CardFooter>
        </Card>
      ),
      assignSupervisor ? (
        <SupervisorsListModal
          key={'supervisorsListModal'}
          show={assignSupervisor}
          supervisors={project.supervisors || []}
          dispatch={this.props.dispatch}
          onCancel={() => this.setState({ assignSupervisor: false })}
          assignSupervisor={newSupervisor =>
            this.setState({ assignSupervisor: false }, () =>
              this.updateSupervisors(newSupervisor)
            )
          }
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(AssignSupervisor));
