import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Pros.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Pros.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = pro => (dispatch, getState, { api }) =>
  api.Pros.save(pro)
    .then(response => {
      dispatch(notify('info', 'Professional saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Pros.del(id)
    .then(response => {
      dispatch(notify('info', 'Professional removed'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const logo = (id, file) => (dispatch, getState, { api }) =>
  api.Pros.logo(id, file)
    .then(response => {
      dispatch(notify('info', 'Professional logo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const photo = (id, file) => (dispatch, getState, { api }) =>
  api.Pros.photo(id, file)
    .then(response => {
      dispatch(notify('info', 'Professional photo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
