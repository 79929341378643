import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import { PaginationCustom, Spinner } from '../../../components';
import { all } from '../../../helpers/actions/supervisors';
import { supervisorName } from '../../../helpers/formatters';
import defaultAvatar from '../../../assets/img/default-avatar.png';

class SupervisorsListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      supervisors: [],
      count: 0,
      page: 1,
      limit: 5,
      search: '',
      currents: [],
      selected: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;

    const offset = page * limit - limit > 0 ? page * limit - limit : 0;
    dispatch(all({ limit, offset, search: search })).then(({ meta, data }) =>
      this.setState({
        supervisors: data,
        ...meta,
        loading: false
      })
    );
  }

  componentWillMount() {
    let { supervisors } = this.props;
    this.setState(
      ps => ({
        ...ps,
        currents: supervisors ? supervisors : null,
        selected: null
      }),
      () => this.load()
    );
  }

  render() {
    let {
        loading,
        search,
        supervisors,
        selected,
        page,
        limit,
        count,
        currents
      } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'md'}
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          <Trans>Select Supervisor</Trans>
        </ModalHeader>
        <ModalBody>
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Input
                  type="text"
                  placeholder={`${t('Search')}...`}
                  value={search}
                  onChange={event =>
                    this.setState(
                      {
                        page: 1,
                        search: event.target.value
                      },
                      () => this.load()
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Table responsive>
            <tbody>
              {supervisors.map((supervisor, key) => {
                let inside = currents.find(s => {
                  let sup = JSON.parse(JSON.stringify(s));
                  return supervisor._id === sup._id;
                });

                return (
                  <tr key={key} className={inside ? 'bg-lightgrey ' : ''}>
                    <td className="text-center">
                      <FormGroup className="form-check-radio">
                        <Label check>
                          <Input
                            type="radio"
                            name="radios"
                            checked={
                              selected !== null &&
                              supervisor._id === selected._id
                            }
                            disabled={inside}
                            onChange={() =>
                              this.setState({ selected: supervisor })
                            }
                          />
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </td>
                    <td>
                      <div
                        className="avatar-image-table"
                        style={{
                          backgroundImage: `url('${
                            supervisor.photoURL
                              ? supervisor.photoURL
                              : defaultAvatar
                          }')`
                        }}
                      />
                    </td>
                    <td className="td-name">{supervisorName(supervisor)}</td>
                    <td className="td-name">
                      {supervisor.email ? supervisor.email : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col xs={12} className={'text-center'}>
              <PaginationCustom
                className="display-flex justify-content-center"
                page={page}
                count={count}
                maxPerPage={limit}
                onPageChange={page =>
                  this.setState({ page }, () => this.load())
                }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.onCancel()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => this.props.assignSupervisor(selected)}
          >
            <Trans>Assign</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(SupervisorsListModal)
);
