import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import get from 'lodash/get';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { providerAvatar, providerName } from '../../helpers/formatters';

class ProviderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  render() {
    let { selected } = this.state;
    let { provider } = this.props;

    return (
      <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState(ps => ({ ...ps, selected: !selected }))}
        >
          <Row>
            <Col xs={3} lg={2} className={'pl-1 pr-1'}>
              <div
                className="client-avatar img-raised"
                style={{
                  backgroundImage: `url('${providerAvatar(provider)}')`
                }}
              />
            </Col>
            <Col xs={9} lg={7}>
              <h6>{providerName(provider)}</h6>
              {get(provider, 'contact.email', '')}
            </Col>
            <Col xs={12} lg={3} className={'text-right pl-1 pr-1'}>
              <Button
                id={`edit-${provider._id}`}
                className={'btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(provider)}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`edit-${provider._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${provider._id}`}
                className={'btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(provider)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${provider._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={'text-center pt-0 pb-0'}
          style={{ display: selected ? 'block' : 'none' }}
        >
          <hr />
          <strong>
            <Trans>Contact Name</Trans>
          </strong>
          <div>
            <span className={'pr-3'}>
              <i className="fa fa-user text-primary icon-action" />
            </span>
            {get(provider, 'contact.fullname', '-')}
          </div>
          <hr />
          <strong>
            <Trans>Website</Trans>
          </strong>
          <div>
            <span className={'pr-3'}>
              <i className="fa fa-laptop text-primary icon-action" />
            </span>
            {get(provider, 'website', '-')}
          </div>
          <hr />
          <strong>
            <Trans>Address</Trans>
          </strong>
          <div>
            <span className={'pr-3'}>
              <i className="fa fa-map-marker text-primary icon-action" />
            </span>
            {get(provider, 'address', '-')}
          </div>
          <hr />
          <strong>
            <Trans>Phones</Trans>
          </strong>
          <div>
            <ul className="fa-ul inline-block text-left">
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(provider, 'phone.mobile', '-')}  (Mobile)`}
              </li>
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(provider, 'phone.landline', '-')}  (Landline)`}
              </li>
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(provider, 'phone.work', '-')}  (Work)`}
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(ProviderCard));
