import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { PaginationCustom, Spinner } from '../../components';
import api from '../../helpers/api/images';

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      images: [],
      count: 0,
      page: 1,
      search: '',
      type: '',
      limit: 18
    };
  }

  componentWillReceiveProps() {
    this.load();
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });
    let { page, limit, search } = this.state;
    let { type = '' } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    api
      .all({ limit, offset, search, type })
      .then(({ data, meta }) => {
        this.setState({ images: data, ...meta, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  close() {
    this.setState({ images: [] }, () => this.props.closeGallery());
  }

  render() {
    let { loading, page, limit, count, images } = this.state;

    return (
      <Modal size="lg" isOpen={true} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          <Trans>Gallery</Trans>
        </ModalHeader>
        <ModalBody>
          <div style={{ position: 'relative' }}>
            {loading ? <Spinner /> : null}
            {images.length > 0 ? (
              <div>
                <Row>
                  {images.map((image, key) => (
                    <Col key={key} xs={6} sm={4} md={4} lg={3}>
                      <div
                        onClick={() => this.props.selectImage(image.photo)}
                        className="gallery-image"
                        style={{
                          backgroundImage: `url('${image.photo}')`
                        }}
                      />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col xs={12} className={'text-center'}>
                    <PaginationCustom
                      className="display-flex justify-content-center"
                      page={page}
                      count={count}
                      maxPerPage={limit}
                      onPageChange={page =>
                        this.setState({ page }, () => this.load())
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col xs={12} md={12}>
                  <Card>
                    <CardBody>
                      <p className={'text-not-found text-center'}>
                        <Trans>No images found</Trans>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(Gallery));
