import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import { all, remove } from '../../helpers/actions/partners';
import PartnerCard from './PartnerCard';
import PartnerModal from './PartnerModal';

class Partners extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    filters: {},
    search: '',
    partner: null,
    partnerDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(search) {
    this.setState({ search, page: 1 }, () => this.load());
  }

  deletePartner(id) {
    this.setState({ loading: true, partnerDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      partner,
      partnerDelete
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col className={'text-left'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event => this.filter(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ partner: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <div style={{ position: 'relative' }}>
            {loading ? <Spinner /> : null}

            {data.length > 0 ? (
              <div>
                <Row>
                  {data.map((contact, key) => (
                    <Col key={key} xs={12} sm={12} md={6} lg={4}>
                      <PartnerCard
                        partner={contact}
                        onEdit={partnerEdit =>
                          this.setState({ partner: partnerEdit })
                        }
                        onDelete={deletePartner =>
                          this.setState({ partnerDelete: deletePartner })
                        }
                      />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col xs={12} className={'text-center'}>
                    <PaginationCustom
                      className="display-flex justify-content-center"
                      page={page}
                      count={count}
                      maxPerPage={limit}
                      onPageChange={page =>
                        this.setState({ page }, () => this.load())
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col xs={12} md={12}>
                  <Card>
                    <CardBody>
                      <p className={'text-not-found text-center'}>
                        <Trans>No partners found</Trans>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>

          {!!partner ? (
            <PartnerModal
              show={!!partner}
              partner={partner}
              dispatch={this.props.dispatch}
              onCancel={() => this.setState({ partner: null })}
              onConfirm={() =>
                this.setState({ partner: null }, () => this.load())
              }
            />
          ) : null}

          {!partnerDelete ? null : (
            <AlertConfirm
              message={'The partner cannot be recovered'}
              onCancel={() => this.setState({ partnerDelete: null })}
              onConfirm={() => this.deletePartner(partnerDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Partners));
