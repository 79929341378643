import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import { AlertConfirm } from '../../../components';
import get from 'lodash/get';
import { proAvatar, proName } from '../../../helpers/formatters';
import { save } from '../../../helpers/actions/projects';
import { evaluateProStatus } from '../../../helpers/nomenclators';
import ProsListModal from './ProsListModal';
import EvaluateModal from './EvaluateModal';
import { isMobileOnly } from 'react-device-detect';

class AssignPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      assignPro: false,
      proDelete: false,
      evaluatePro: false
    };
  }

  componentWillReceiveProps({ project }) {
    this.setState({ project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  assignPro(newPro) {
    let { dispatch } = this.props,
      { project } = this.state;

    dispatch(save({ _id: project._id, pro: newPro }))
      .then(() => this.props.reload())
      .catch(() => {});
  }

  evaluatePro(evaluation) {
    let { dispatch } = this.props,
      { project } = this.state;

    dispatch(save({ _id: project._id, proEvaluation: evaluation }))
      .then(() => this.props.reload())
      .catch(() => {});
  }

  deletePro() {
    this.setState({ loading: true });

    let { dispatch } = this.props,
      { project } = this.state;

    dispatch(save({ _id: project._id, pro: null }))
      .then(() => this.props.reload())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { project, assignPro, proDelete, evaluatePro } = this.state;

    return [
      isMobileOnly ? (
        <Row key={'assignPro'} className="user-data">
          <Col xs={12}>
            <div
              className="project-detail-avatar"
              style={{
                backgroundImage: `url('${proAvatar(project.pro)}')`
              }}
            />
          </Col>
          <Col xs={12}>
            <CardTitle tag="h5" className={'text-center'}>
              {proName(project.pro)}
            </CardTitle>
          </Col>
          <Col xs={12}>
            {project.pro ? (
              <div>
                <ul>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Email</Trans>:
                    </strong>
                    {get(project, 'pro.email', '') ? (
                      <a
                        href={`mailto:${get(
                          project,
                          'pro.email',
                          ''
                        )}?Subject=Placeshaker`}
                        target="_top"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'pro.email', '')}
                      </a>
                    ) : null}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Address</Trans>:
                    </strong>
                    {get(project, 'pro.lat', null) &&
                    get(project, 'pro.lng', null) ? (
                      <a
                        href={`https://www.waze.com/ul?ll=${
                          project.pro.lat
                        }%2C${project.pro.lng}&zoom=17`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'pro.address', '')}
                      </a>
                    ) : (
                      get(project, 'pro.address', '')
                    )}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Mobile</Trans>:
                    </strong>
                    <a href={`tel:${get(project, 'pro.phone.mobile', '')}`}>
                      {get(project, 'pro.phone.mobile', '')}
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <p className="text-center">
                <Trans>Assign this project to a professional</Trans>
              </p>
            )}
          </Col>
          <Col xs={12} className={'text-center pt-0'}>
            {project.pro && evaluateProStatus.indexOf(project.status) >= 0 ? (
              <Button
                color={'info'}
                size={'sm'}
                className={'mr-2'}
                onClick={() => this.setState({ evaluatePro: true })}
              >
                <i className="fa fa-star-half-full icon-action" />
              </Button>
            ) : null}

            <Button
              color="info"
              size="sm"
              onClick={() => this.setState({ assignPro: true })}
            >
              <Trans>Assign</Trans>
            </Button>
            {project.pro ? (
              <Button
                className={'ml-2'}
                color="danger"
                size="sm"
                onClick={() => this.setState({ proDelete: true })}
              >
                <Trans>Delete</Trans>
              </Button>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Card key={'assignPro'} className="card-flex user-data">
          <CardHeader className={'text-center'}>
            <h6>
              <Trans>Professional</Trans>
            </h6>
          </CardHeader>
          <CardBody>
            <div
              className="project-detail-avatar"
              style={{
                backgroundImage: `url('${proAvatar(project.pro)}')`
              }}
            />
            <CardTitle tag="h5" className={'text-center'}>
              {proName(project.pro)}
            </CardTitle>
            {project.pro ? (
              <div>
                <ul>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Email</Trans>:
                    </strong>
                    {get(project, 'pro.email', '') ? (
                      <a
                        href={`mailto:${get(
                          project,
                          'pro.email',
                          ''
                        )}?Subject=Placeshaker`}
                        target="_top"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'pro.email', '')}
                      </a>
                    ) : null}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Address</Trans>:
                    </strong>
                    {get(project, 'pro.lat', null) &&
                    get(project, 'pro.lng', null) ? (
                      <a
                        href={`https://www.google.com/maps/place/${get(
                          project,
                          'pro.address',
                          ''
                        )}/@=${project.pro.lat},${project.pro.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'pro.address', '')}
                      </a>
                    ) : (
                      get(project, 'pro.address', '')
                    )}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Mobile</Trans>:
                    </strong>
                    <a href={`tel:${get(project, 'pro.phone.mobile', '')}`}>
                      {get(project, 'pro.phone.mobile', '')}
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p className="text-center">
                    <Trans>Assign this project to a professional</Trans>
                  </p>
                </Col>
              </Row>
            )}
          </CardBody>
          <CardFooter className={'text-center pt-0'}>
            {project.pro && evaluateProStatus.indexOf(project.status) >= 0 ? (
              <Button
                color={'info'}
                size={'sm'}
                className={'mr-2'}
                onClick={() => this.setState({ evaluatePro: true })}
              >
                <i className="fa fa-star-half-full icon-action" />
              </Button>
            ) : null}

            <Button
              color="info"
              size="sm"
              onClick={() => this.setState({ assignPro: true })}
            >
              <Trans>Assign</Trans>
            </Button>
            {project.pro ? (
              <Button
                className={'ml-2'}
                color="danger"
                size="sm"
                onClick={() => this.setState({ proDelete: true })}
              >
                <Trans>Delete</Trans>
              </Button>
            ) : null}
          </CardFooter>
        </Card>
      ),
      assignPro ? (
        <ProsListModal
          key={'prosListModal'}
          show={assignPro}
          pro={project.pro}
          dispatch={this.props.dispatch}
          onCancel={() => this.setState({ assignPro: false })}
          assignPro={newPro =>
            this.setState({ assignPro: false }, () => this.assignPro(newPro))
          }
        />
      ) : null,
      evaluatePro ? (
        <EvaluateModal
          key={'evaluateModal'}
          show={evaluatePro}
          evaluate={project.proEvaluation ? project.proEvaluation : null}
          dispatch={this.props.dispatch}
          onCancel={() => this.setState({ evaluatePro: false })}
          evaluatePro={evaluation =>
            this.setState({ evaluatePro: false }, () =>
              this.evaluatePro(evaluation)
            )
          }
        />
      ) : null,
      proDelete ? (
        <AlertConfirm
          key={'deletePro'}
          message={'The professional will be unassigned from the project'}
          onCancel={() => this.setState({ proDelete: false })}
          onConfirm={() =>
            this.setState({ proDelete: false }, () => this.deletePro())
          }
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(AssignPro));
