import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import ReactQuill from 'react-quill';
import PlacesAutocomplete from 'react-places-autocomplete';
import get from 'lodash/get';
import { providerAvatar, quillConfig } from '../../helpers/formatters';
import { GoogleMapLibrary } from '../../components';

class ProviderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: {},
      file: null
    };
  }

  componentWillMount() {
    let { provider } = this.props;
    if (!provider._id) provider = { tva: 10, proPercent: 80 };
    if (provider) this.setState(ps => ({ ...ps, provider }));
  }

  cleanModal() {
    this.setState({
      nameValid: null,
      siretValid: null,
      fullnameValid: null,
      websiteValid: null,
      emailValid: null,
      addressValid: null,
      mobileValid: null,
      workValid: null,
      landlineValid: null,
      cgvValid: null,
      commissionValid: null,
      proPercentValid: null,
      intermediaryValid: null,
      tvaValid: null,
      provider: {},
      file: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  handleImageChange(file) {
    let { provider } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      provider.logo = reader.result;
      this.setState(ps => ({ ...ps, provider, file: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  onChangeProvider(name, nameValid, value, valid) {
    let { provider } = this.state;

    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState(ps => ({
        ...ps,
        provider: { ...provider, phone: { ...provider.phone, [name]: value } },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      }));
    } else if (name === 'fullname' || name === 'email') {
      this.setState(ps => ({
        ...ps,
        provider: {
          ...provider,
          contact: { ...provider.contact, [name]: value }
        },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      }));
    } else {
      this.setState(ps => ({
        ...ps,
        provider: { ...provider, [name]: value },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      }));
    }
  }

  validate() {
    let name = this.name;
    let siret = this.siret;
    let email = this.email;
    let fullname = this.fullname;
    let website = this.website;
    let address = this.address;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;
    let commission = this.commission;
    let proPercent = this.proPercent;
    let intermediary = this.intermediary;
    let tva = this.tva;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [siret.attributes.getNamedItem('namevalid').value]: siret.validity.valid
        ? 'has-success'
        : 'has-danger',
      [fullname.attributes.getNamedItem('namevalid').value]: fullname.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [website.attributes.getNamedItem('namevalid').value]: website.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      cgvValid: 'has-success',
      [commission.attributes.getNamedItem('namevalid').value]: commission
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [proPercent.attributes.getNamedItem('namevalid').value]: proPercent
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [intermediary.attributes.getNamedItem('namevalid').value]: intermediary
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [tva.attributes.getNamedItem('namevalid').value]: tva.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      siret.validity.valid &&
      email.validity.valid &&
      address.validity.valid &&
      fullname.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid &&
      website.validity.valid &&
      commission.validity.valid &&
      proPercent.validity.valid &&
      intermediary.validity.valid &&
      tva.validity.valid
    );
  }

  onSaveProvider() {
    let { provider, file } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(provider, file);
    }
  }

  render() {
    let { provider } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {provider && provider._id ? t('Edit Provider') : t('Add Provider')}
        </ModalHeader>
        <ModalBody>
          {provider ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} className={'display-flex justify-content-center'}>
                  <div
                    className="avatar-image"
                    style={{
                      backgroundImage: `url('${providerAvatar(provider)}')`
                    }}
                  />
                </Col>
                <Col xs={12} className={'justify-content-center text-center'}>
                  <Button
                    color={'info'}
                    round="true"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Change</Trans>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.nameValid
                    }
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={provider.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.siretValid
                    }
                  >
                    <Label>
                      <Trans>Siret</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.siret = node)}
                      namevalid="siretValid"
                      value={provider.siret || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'siret',
                          'siretValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.websiteValid
                    }
                  >
                    <Label>
                      <Trans>Website</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.website = node)}
                      namevalid="websiteValid"
                      value={provider.website || ''}
                      onChange={event =>
                        this.onChangeProvider(
                          'website',
                          'websiteValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.addressValid
                    }
                  >
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={provider.address ? provider.address : ''}
                        onChange={address =>
                          this.onChangeProvider(
                            'address',
                            'addressValid',
                            address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.emailValid
                    }
                  >
                    <Label>
                      <Trans>Contact Email</Trans>
                    </Label>
                    <Input
                      type="email"
                      innerRef={node => (this.email = node)}
                      namevalid="emailValid"
                      value={get(provider, 'contact.email', '')}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'email',
                          'emailValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.fullnameValid
                    }
                  >
                    <Label>
                      <Trans>Contact Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.fullname = node)}
                      namevalid="fullnameValid"
                      value={get(provider, 'contact.fullname', '')}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'fullname',
                          'fullnameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.mobileValid
                    }
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={get(provider, 'phone.mobile', '')}
                      onChange={event =>
                        this.onChangeProvider(
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.workValid
                    }
                  >
                    <Label>
                      <Trans>Work</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={get(provider, 'phone.work', '')}
                      onChange={event =>
                        this.onChangeProvider(
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.landlineValid
                    }
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={get(provider, 'phone.landline', '')}
                      onChange={event =>
                        this.onChangeProvider(
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.commissionValid
                    }
                  >
                    <Label>
                      <Trans>Commission Percent</Trans> (%)
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.commission = node)}
                      namevalid="commissionValid"
                      value={provider.commission || ''}
                      step={1}
                      min={1}
                      max={100}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'commission',
                          'commissionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.proPercentValid
                    }
                  >
                    <Label>
                      <Trans>Pro Percent</Trans> (%)
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.proPercent = node)}
                      namevalid="proPercentValid"
                      value={provider.proPercent || ''}
                      step={1}
                      min={1}
                      max={100}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'proPercent',
                          'proPercentValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.intermediaryValid
                    }
                  >
                    <Label>
                      <Trans>Intermediary Percent</Trans> (%)
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.intermediary = node)}
                      namevalid="intermediaryValid"
                      value={provider.intermediary || ''}
                      step={1}
                      min={1}
                      max={100}
                      onChange={event =>
                        this.onChangeProvider(
                          'intermediary',
                          'intermediaryValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.tvaValid
                    }
                  >
                    <Label>
                      <Trans>TVA</Trans> (%)
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.tva = node)}
                      namevalid="tvaValid"
                      value={provider.tva || ''}
                      step={1}
                      min={0}
                      max={100}
                      required="required"
                      onChange={event =>
                        this.onChangeProvider(
                          'tva',
                          'tvaValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.cgvValid
                    }
                  >
                    <Label>
                      <Trans>CGV</Trans>
                    </Label>
                    <ReactQuill
                      value={provider.cgv || ''}
                      modules={quillConfig}
                      className={'quill-text-editor'}
                      onChange={value =>
                        this.onChangeProvider('cgv', 'cgvValid', value, true)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveProvider()}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(ProviderModal));
