import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip
} from 'reactstrap';
import { AlertConfirm, Spinner, TableCustom } from '../../../components';
import {
  allDevisDocuments,
  createDevisDocuments,
  delDevisDocuments
} from '../../../helpers/actions/projects';

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      invoices: [],
      docDelete: null
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId, devisId } = this.props;

    dispatch(allDevisDocuments(projectId, devisId))
      .then(documents =>
        this.setState({ invoices: documents, loading: false, projectId })
      )
      .catch(() => this.setState({ loading: false }));
  }

  getInvoicesData() {
    let { invoices } = this.state;

    return invoices.map(document => {
      return {
        className: '',
        data: [
          {
            className: '',
            value: (
              <a href={document.source} target={'_blank'}>
                {document.name}
              </a>
            )
          },
          {
            className: '',
            fixed: false,
            value:
              document.user && document.user[0]
                ? document.user[0].displayName
                : ''
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Button
                key={'button1-' + document._id}
                id={'delete' + document._id}
                className={'ml-1 btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.setState({ docDelete: document._id })}
              >
                <i className="fa fa-trash icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={'tooltip1-' + document._id}
                placement="left"
                target={'delete' + document._id}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            ]
          }
        ]
      };
    });
  }

  uploadClick() {
    let { devisId } = this.props;
    let input = document.getElementById(`uploadDoc${devisId}`);
    input.click();
  }

  documentChange(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let { dispatch, projectId, devisId } = this.props;
    let files = e.target.files;

    dispatch(createDevisDocuments(projectId, devisId, files))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  remove() {
    this.setState({ loading: true });
    let { docDelete } = this.state,
      { dispatch, projectId, devisId } = this.props;

    dispatch(delDevisDocuments(projectId, devisId, docDelete))
      .then(() => this.setState({ docDelete: null }, () => this.load()))
      .catch(() => this.setState({ docDelete: null, loading: false }));
  }

  render() {
    let { loading, docDelete } = this.state,
      { t, devisId } = this.props;
    let rows = this.getInvoicesData();

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <input
              type="file"
              id={`uploadDoc${devisId}`}
              name={`uploadDoc${devisId}`}
              accept="application/pdf"
              multiple={true}
              hidden={true}
              onChange={event => this.documentChange(event)}
            />
            <Button size="sm" color="info" onClick={() => this.uploadClick()}>
              <Trans>Add</Trans>
            </Button>
          </CardHeader>
          <CardBody>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[
                { value: t('Name') },
                { value: t('User') },
                { value: '', className: 'text-right' }
              ]}
              rows={rows}
              emptyMessage={t('No invoices found')}
            />
          </CardBody>
        </Card>

        {docDelete ? (
          <AlertConfirm
            message={'The devis document cannot be recovered'}
            onCancel={() => this.setState({ docDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Invoices));
