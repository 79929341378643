import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import { PaginationCustom, Spinner } from '../../../components';
import get from 'lodash/get';
import { all } from '../../../helpers/actions/pros';
import { proAvatar, proName } from '../../../helpers/formatters';

class ProsListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      professionals: [],
      count: 0,
      page: 1,
      limit: 5,
      search: '',
      selected: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search: search }))
      .then(({ meta, data }) =>
        this.setState(ps => ({
          ...ps,
          professionals: data,
          ...meta,
          loading: false
        }))
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    let { pro } = this.props;
    this.setState(
      ps => ({ ...ps, selected: pro ? pro : null }),
      () => this.load()
    );
  }

  render() {
    let {
        loading,
        search,
        professionals,
        selected,
        page,
        limit,
        count
      } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'md'}
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          <Trans>Select Professional</Trans>
        </ModalHeader>
        <ModalBody>
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Input
                  type="text"
                  placeholder={`${t('Search')}...`}
                  value={search}
                  onChange={event =>
                    this.setState(
                      {
                        page: 1,
                        search: event.target.value
                      },
                      () => this.load()
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Table responsive>
            <tbody>
              {professionals.map((pro, key) => {
                return (
                  <tr key={key}>
                    <td className="text-center">
                      <FormGroup className="form-check-radio">
                        <Label check>
                          <Input
                            type="radio"
                            name="radios"
                            checked={
                              selected !== null && pro._id === selected._id
                            }
                            onChange={() => this.setState({ selected: pro })}
                          />
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </td>
                    <td>
                      <div
                        className="avatar-image-table"
                        style={{
                          backgroundImage: `url('${proAvatar(pro)}')`
                        }}
                      />
                    </td>
                    <td className="td-name">{proName(pro)}</td>
                    <td className="td-name">{get(pro, 'email', '')}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col xs={12} className={'text-center'}>
              <PaginationCustom
                className="display-flex justify-content-center"
                page={page}
                count={count}
                maxPerPage={limit}
                onPageChange={page =>
                  this.setState({ page }, () => this.load())
                }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.onCancel()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.props.assignPro(selected)}>
            <Trans>Assign</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(ProsListModal)
);
