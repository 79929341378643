import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Rank } from '../../../components';

class EvaluateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluate: {}
    };
  }

  cleanModal() {
    this.setState({
      infoValid: null,
      evaluate: {}
    });
  }

  componentWillMount() {
    let { evaluate } = this.props;
    this.setState(ps => ({ ...ps, evaluate }));
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { evaluate } = this.state;

    this.setState(ps => ({
      ...ps,
      evaluate: { ...evaluate, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    }));
  }

  validate() {
    let info = this.info;

    this.setState({
      [info.attributes.getNamedItem('namevalid').value]: info.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return info.validity.valid;
  }

  onSaveEvaluation() {
    let { evaluate } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.evaluatePro(evaluate);
    }
  }

  updateRank(rank) {
    this.setState(prevState => {
      return {
        ...prevState,
        evaluate: { ...prevState.evaluate, rank }
      };
    });
  }

  render() {
    let { evaluate } = this.state;

    return (
      <Modal
        size={'md'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Evaluate Pro</Trans>
        </ModalHeader>
        <ModalBody>
          {evaluate ? (
            <Row>
              <Col className={'text-center'} xs={12}>
                <Rank
                  items={5}
                  rank={evaluate.rank}
                  setRank={v => this.updateRank(v)}
                />
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.infoValid
                  }
                >
                  <Label>
                    <Trans>Description</Trans>
                  </Label>
                  <Input
                    type="textarea"
                    innerRef={node => (this.info = node)}
                    namevalid="infoValid"
                    value={evaluate.info || ''}
                    required="required"
                    onChange={event =>
                      this.onChange(
                        'info',
                        'infoValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveEvaluation()}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(EvaluateModal)
);
