import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import {
  PaginationCustom,
  PanelHeader,
  Spinner,
  TableCustom
} from '../../components';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { all as allProviders } from '../../helpers/actions/providers';
import { adminEvents } from '../../helpers/actions/projects';
import { dateFormatter, supervisorName } from '../../helpers/formatters';
import { eventTypes } from '../../helpers/nomenclators';
import { getUrlParam } from '../../helpers/urlParams';
import { all as allSupervisors } from '../../helpers/actions/supervisors';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const dates = [
  {
    name: 'Last 7 Days',
    value: dateFormatter(moment().subtract(7, 'days'), dateFormat)
  },
  {
    name: 'Last Month',
    value: dateFormatter(moment().subtract(1, 'months'), dateFormat)
  },
  {
    name: 'Last 3 Months',
    value: dateFormatter(moment().subtract(3, 'months'), dateFormat)
  },
  {
    name: 'Last 6 Months',
    value: dateFormatter(moment().subtract(6, 'months'), dateFormat)
  },
  {
    name: 'Last Year',
    value: dateFormatter(moment().subtract(1, 'years'), dateFormat)
  },
  {
    name: 'Next 7 Days',
    value: dateFormatter(moment().add(7, 'days'), dateFormat)
  },
  {
    name: 'Next Month',
    value: dateFormatter(moment().add(1, 'months'), dateFormat)
  },
  {
    name: 'Next 3 Months',
    value: dateFormatter(moment().add(3, 'months'), dateFormat)
  },
  {
    name: 'Next 6 Months',
    value: dateFormatter(moment().add(6, 'months'), dateFormat)
  },
  {
    name: 'Next Year',
    value: dateFormatter(moment().add(1, 'years'), dateFormat)
  }
];

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      events: [],
      count: 0,
      page: 1,
      limit: 18,
      type: '',
      provider: '',
      providers: [],
      supervisor: '',
      supervisors: [],
      date: dateFormatter(moment().subtract(7, 'days'), dateFormat),
      dateFrom: '',
      dateTo: ''
    };
  }

  load() {
    this.setState({ loading: true, events: [] });
    let {
        page,
        limit,
        type,
        provider,
        supervisor,
        date,
        dateFrom,
        dateTo
      } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    if (moment().isAfter(date, dateFormat)) {
      dateFrom = date;
      dateTo = moment().format(dateFormat);
    } else {
      dateTo = date;
      dateFrom = moment().format(dateFormat);
    }

    dispatch(
      adminEvents({
        limit,
        offset,
        type,
        provider,
        dateFrom,
        dateTo,
        supervisor
      })
    )
      .then(result => {
        this.setState({
          events: result.data,
          ...result.meta,
          provider,
          supervisor,
          dateFrom,
          dateTo,
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillMount() {
    let type = getUrlParam('type', '');
    let provider = getUrlParam('provider', '');
    let supervisor = getUrlParam('supervisor', '');
    let date = getUrlParam('date', null);

    this.setState(ps => ({
      ...ps,
      type,
      provider,
      supervisor,
      date: date
        ? date
        : dateFormatter(moment().subtract(7, 'days'), dateFormat)
    }));
  }

  componentDidMount() {
    this.setState({ loading: true });

    let {
        page,
        limit,
        type,
        provider,
        supervisor,
        date,
        dateFrom,
        dateTo
      } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    if (moment().isAfter(date, dateFormat)) {
      dateFrom = date;
      dateTo = moment().format(dateFormat);
    } else {
      dateTo = date;
      dateFrom = moment().format(dateFormat);
    }

    Promise.all([
      dispatch(
        adminEvents({
          limit,
          offset,
          type,
          provider,
          supervisor,
          dateFrom,
          dateTo
        })
      ),
      dispatch(allProviders({ limit: 100 })),
      dispatch(allSupervisors({ limit: 1000 }))
    ])
      .then(([{ meta, data }, providers, supervisors]) => {
        this.setState({
          events: data,
          ...meta,
          provider,
          providers: providers.data,
          supervisors: supervisors.data,
          dateFrom,
          dateTo,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  getEventsData() {
    let { events } = this.state,
      { t } = this.props;

    return events.map(event => {
      return {
        className: '',
        data: [
          {
            className: '',
            fixed: true,
            value: (
              <Link to={`/projects/${event.project.id}`}>
                {event.project.name}
              </Link>
            )
          },
          { className: '', fixed: true, value: event.title },
          { className: '', fixed: false, value: t(event.typeEvent) },
          {
            className: 'text-center',
            fixed: false,
            value: dateFormatter(event.start, 'llll')
          },
          {
            className: 'text-justify',
            fixed: false,
            properties: { width: '35%' },
            value: <div dangerouslySetInnerHTML={{ __html: event.text }} />
          }
        ]
      };
    });
  }

  render() {
    let {
      loading,
      page,
      limit,
      count,
      type,
      providers,
      provider,
      supervisors,
      supervisor,
      date,
      dateFrom,
      dateTo
    } = this.state;
    let { t } = this.props;
    let rows = this.getEventsData();

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <Form>
                <Row>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={eventTypes.map(p => ({
                          label: t(p),
                          value: p
                        }))}
                        value={type ? type : ''}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              type: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Provider</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={providers.map(p => ({
                          label: p.name,
                          value: p._id
                        }))}
                        value={provider}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              provider: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Supervisor</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={supervisors.map(sup => ({
                          label: supervisorName(sup),
                          value: sup._id
                        }))}
                        value={supervisor}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              supervisor: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Period</Trans>
                      </Label>
                      <Select
                        className="primary"
                        clearable={false}
                        options={dates.map(d => ({
                          label: d.name,
                          value: d.value
                        }))}
                        value={date}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              date: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4}>
                    <Link
                      to={`/projects/reports/events?type=${type}&provider=${provider}&supervisor=${supervisor}&dateFrom=${dateFrom}&dateTo=${dateTo}`}
                      className="btn btn-info float-right"
                    >
                      <Trans>Report</Trans>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </CardHeader>
            <CardBody>
              <TableCustom
                minWidth={767}
                className={'devi-item-material-table'}
                accordion={true}
                heads={[
                  { value: t('Project') },
                  { value: t('Title') },
                  { value: t('Type') },
                  { value: t('Start Date'), className: 'text-center' },
                  { value: t('Description'), className: 'text-justify' }
                ]}
                rows={rows}
                emptyMessage={t('No events found')}
              />
            </CardBody>
          </Card>
          <Row>
            <Col xs={12} className={'text-center'}>
              <PaginationCustom
                className="display-flex justify-content-center"
                page={page}
                count={count}
                maxPerPage={limit}
                onPageChange={page =>
                  this.setState({ page }, () => this.load())
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Events));
